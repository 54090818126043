import React from "react"
import { graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import styled from 'styled-components'

const ContentfulContent = styled.div`
  ul {
  font-family: halyard-display, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.25rem;
  list-style-type: disc;
  color: #212529;
  }
`

const PrivacyPolicy = ({ data }) => {
  const page = data.allContentfulPage.nodes[0]
    
    const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: ({
            data: {
              target: { fields },
            },
          }) => (
            <div
              dangerouslySetInnerHTML={{
                __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
              }}
            />
          ),
    
          [BLOCKS.EMBEDDED_ENTRY]: node => {
            const fields = node.data.target.fields.imageLink
            const imgLnk = node.data.target.fields.url
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
                }}
              />
            )
          },
        },
      }
  return (
  <Layout>
    <SEO title="Symphony Website Privacy Policy" />
    <Container>
      <Row className="my-4">
        <Col>
          <h1 className="text-center">Symphony for Seniors LLC</h1>
          <h2 className="text-center">Notice of Privacy Practices</h2>
          <ContentfulContent>
          {documentToReactComponents(
                page.childContentfulPagePageContentRichTextNode.json,
                options
              )}
          </ContentfulContent>
        </Col>
      </Row>
    </Container>
  </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPage(filter: {title: {eq: "Notice of Privacy Practices"}}) {
      nodes {
        title
        childContentfulPagePageContentRichTextNode {
          json
        }
      }
    }
  }
`

export default PrivacyPolicy
